import { Match, Switch, createEffect, createSignal } from "solid-js";
import { solidstate } from ":shared/utils/state-manager";
import { get_agenda } from "./apis";
import type { MainProps } from "./_model";
import EventDisplay from "./components/EventDisplay";
import { Loader2 } from ":src/components/loaders";

export default function Agenda(props: MainProps) {
  const $agenda_list = solidstate.createAsync(get_agenda);
  return (
    <section class="flex flex-col w-full min-h-full h-full md:px-5 md:py-3">
      <h1 class="text-xl py-2 font-[600] text-black z-1 <md:(text-22px py-2 px-2)">Agenda</h1>
      <Switch>
        <Match when={$agenda_list.state.loading}>
          <div class="flex w-full min-h-screen md:h-full items-center justify-center">
            <Loader2 msg="Loading Agenda Details..." />
          </div>
        </Match>
        <Match when={$agenda_list.state.failed}>an error occurred while fetching {$agenda_list.error}</Match>
        <Match when={$agenda_list.state.success}>
          <div class="flex flex-row w-full h-full  overflow-auto <md:(px-2 py-2)">
            <EventDisplay events={$agenda_list.unwrap?.value} />
          </div>
        </Match>
      </Switch>
    </section>
  );
}
